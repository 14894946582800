import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const InvitatiilePage = () => (
  <Layout>
    <SEO title="Cum alegem invitațiile de nuntă?" />
    <div className="drawer article-content">
      <h1 className="article-title">Cum alegem invitațiile de nuntă?</h1>

      <p>
        Cărțile de vizită ale evenimentului vostru. Invitațiile de nuntă sunt un
        fel de preview al zilei voastre speciale, tocmai pentru că sunt primul
        lucru pe care îl vor vedea invitații. Sigur că te vei gândi că cine va
        vrea să fie lângă tine nu se va gândi la ce fel de invitație va primi
        sau că mulți invitați o vor arunca, însă gândește-te că este prima
        imagine pe care invitații și-o vor creiona despre nunta voastră. Nu-i
        așa că vrei să fie una deosebită? Invitațiile ar trebui să reflecte atât
        tematica aleasă de voi pentru nuntă, cât și personalitățile voastre.
      </p>

      <h3 className="paragh-title">Câteva idei de invitații</h3>
      <p>
        Clasice sau minimaliste - o singură pagină care poate fi simplă și
        într-o singură culoare sau pe care poți adăuga câteva elemente specifice
        nunții tale (mare și nisip pentru o nuntă cu tematică marină, flori
        specifice dacă ai ales o floare anume pentru decorațiuni și buchet,
        câteva frunzulițe dacă ați ales alb și verde ca și culori predominante)
        Încadrate în tematică - un bilet de avion, de tren sau un pașaport, un
        papirus pentru elemente vintage. Deosebite - invitații puzzle, invitații
        3D, invitație cu poza/pozele voastre.
      </p>

      <h3 className="paragh-title">Online sau în format fizic?</h3>
      <p>
        Puteți opta și pentru varianta invitațiilor trimise online atunci când
        nu te poți întâlni cu toți invitații, mai ales ca va însemna și o
        reducere a costurilor. Totuși ideal ar fi ca măcar persoanelor apropiate
        să le dai invitația în format fizic.
      </p>

      <h3 className="paragh-title">Ce informații trecem pe invitație?</h3>
      <p>
        Indiferent că alegi să scrii un text mai mare sau mai mic, să scrii o
        poezie, povestea voastră sau doar informațiile de bază, informațiile ce
        nu ar trebui să lipsească: Numele mirilor. Dacă alegi să treci doar
        numele vostru, fără numele părinților și al nașilor poate ar fi util să
        treci și numele părinților, astfel încât dacă și părinții voștri invită
        pe cineva, invitatul să știe a cui nuntă este. Data nunții, ora cununiei
        religioase și ora la care începe petrecerea. Locația unde va avea loc
        ceremonia, petrecerea, precum și adresele acestora. Data limită pentru
        confirmarea participării și cum pot confirma. Poți trece acest lucru pe
        invitație sau poți face un card separat (sub forma unei cărți de
        vizită). În principiu, data limită ar trebui să fie cam cu o lună
        înainte de nuntă. Gândește-te că nu toți îți vor confirma și va fi
        nevoie să îi contactezi, iar în principiu, la restaurante, numărul final
        de invitați trebuie să fie dat cu 10 zile înainte de eveniment.
      </p>

      <h3 className="paragh-title">
        Cu cât timp înainte ar trebui să dăm invitațiile?
      </h3>
      <p>
        Desigur că depinde și de numărul de invitați și de modul în care ai ales
        să împarți invitațiile. Dacă ai ales varianta invitațiilor online îți va
        lua destul de puțin timp trimiterea lor, dacă le trimiți prin poștă
        probabil ar trebui să le trimiți cu vreo 2-3 luni înainte de nuntă, dacă
        decizi să le împarți personal ar trebui să începi cu minim 6 săptămâni
        înainte de nuntă, mai ales dacă data limită pentru confirmare este cu 30
        de zile înainte.
      </p>

      <p>
        Nu uita, micile detalii fac diferența și deși alegerea invitațiilor nu
        trebuie să fie un factor de stres pentru voi, nici nu trebuie să îl
        tratați superficial, încercați să alegeți o invitație potrivită poveștii
        voastre.
      </p>

      <div className="nav-section">
        <Link to="/muzica">◀ Muzica</Link>
        <Link to="/cununiacivila">▶ Cununia Civilă</Link>
      </div>
    </div>
  </Layout>
)

export default InvitatiilePage
